import { createApp } from 'vue'
import App from './App.vue'
import '@/style.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faLocation } from '@fortawesome/free-solid-svg-icons'

library.add(faFacebook, faInstagram, faTelegram, faLocation);
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');

// createApp(App).mount('#app')
