<template>
    <!-- Services section start -->
	<section id="services-section" class="px-12 lg:px-32 py-16 relative overflow-hidden">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 text-white font-semibold ">
                OUR SERVICES
            </h1>
            <p class="capitalize xl:w-1/2 text-stone-100 mb-8">
              Our company provides expert GPS and camera installation services designed to enhance both vehicle security and building surveillance. We specialize in installing GPS systems that allow real-time vehicle tracking and remote engine control, as well as high-quality surveillance cameras for comprehensive monitoring of residential and commercial properties. Additionally, we offer full technical support to ensure that our systems continue to operate seamlessly, providing you with reliable security and peace of mind.
            </p>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Services start -->
        <div class="flex flex-col md:flex-row md:gap-8">
            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start my-8 md:w-1/3">
             <img src="@/assets/services/GPS.jpg" alt="Wedding" class="hidden shadow-2xl rounded-lg md:flex -z-50 md:mb-4 w-full h-56">
              <div class="w-full text-ledt">
                  <h1 class="uppercase text-2xl text-white font-semibold">
                    GPS Installation & Engine Monitoring
                  </h1>
                  <p class="capitalize text-stone-100">
                    We install advanced GPS systems in vehicles, enabling real-time tracking for enhanced safety. Alongside location monitoring, our systems allow remote engine control, meaning you can start or stop your vehicle's engine from anywhere, providing an extra layer of security against theft. These systems also offer detailed reports on driving habits, fuel efficiency, and route history, ensuring a comprehensive overview of your vehicle’s performance.
                  </p>
              </div>
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="text-left w-full md:order-2">
                    <h1 class="uppercase text-2xl font-semibold text-white">
                      Camera Installation for Buildings
                    </h1>
                    <p class="capitalize text-stone-100">
                      We provide top-of-the-line camera installation services for residential, commercial, and industrial buildings. Our high-resolution cameras are designed to capture clear, detailed footage, ensuring that every corner of your property is monitored. Whether you need outdoor cameras for perimeter security or indoor cameras for close monitoring, our installations are customized to meet your specific needs, offering reliable protection around the clock.
                    </p>
                </div>

                <img src="@/assets/services/camera.jpg" alt="Lifestyle" class="hidden md:flex -z-50 md:mb-4 rounded-lg shadow-2xl md:order-1 w-full h-56">
            
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
              <img src="@/assets/services/technical.png" alt="Moments" class="hidden shadow-2xl rounded-lg md:flex -z-50 md:mb-4 w-full h-56">
                <div class="text-left">
                    <h1 class="uppercase text-2xl font-semibold text-white">
                      Technical Support & Maintenance
                    </h1>
                    <p class="capitalize text-stone-100">
                      We offer continuous technical support for all installed systems, ensuring that your GPS trackers and surveillance cameras remain operational and effective. Our support team handles troubleshooting, repairs, and software updates, providing prompt assistance whenever issues arise. Routine maintenance checks ensure that your security infrastructure remains up-to-date and fully functional, giving you peace of mind and uninterrupted protection.
                    </p>
                </div>
            </div>
            <!-- Wedding service end -->
        </div>
        <!-- Services end -->

        <!-- Circle start -->
        <div class="bg-sky-900 h-64 w-64 rounded-full absolute top-0 -right-20 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Services section end -->
</template>

<script setup>

</script>

<style>

</style>
