<template>
  <!-- Portfolio section start -->
  <section id="portfolio-section" class="px-4 text-white lg:px-8 py-16 relative">
    <!-- Text wrapper start -->
    <div class="flex gap-8 flex-col md:flex-row">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl mb-4 font-semibold">
        OUR PORTFOLIO
      </h1>
      <p class="capitalize xl:w-1/2 mb-8">
        Here are some of the organizations we have proudly worked with to deliver outstanding results and ensure their success.
      </p>
      <!-- Main text end -->
    </div>
    <!-- Text wrapper end -->

    <!-- Organizations grid start -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      <div
          v-for="organization in organizations"
          :key="organization.id"
          class="flex flex-col items-center text-center p-4 bg-sky-950 text-white rounded-xl shadow-lg"
      >
        <img :src="organization.logo" :alt="organization.name" class="w-24 h-24 object-contain mb-4" />
        <h3 class="text-xl font-semibold">{{ organization.name }}</h3>
      </div>
    </div>
    <!-- Organizations grid end -->

    <!-- "More" link start -->
    <!-- <a href="#" class="text-end">
      <p class="font-semibold text-lg group relative">
        <span>See more organizations </span>
        <i class="fa-solid fa-arrow-right"></i>
      </p>
    </a> -->
    <!-- "More" link end -->

    <!-- Circle start -->
    <!-- <div class="bg-sky-900 h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"></div> -->
    <!-- Circle end -->
  </section>
  <!-- Portfolio section end -->
</template>

<script>
import org1 from '@/assets/portfolio/bmw.jpg'
import org2 from '@/assets/portfolio/bmw.jpg'
import org3 from '@/assets/portfolio/bmw.jpg'
import org4 from '@/assets/portfolio/bmw.jpg'
import org5 from '@/assets/portfolio/bmw.jpg'
export default {
  data() {
    return {
      organizations: [
        {
          id: 1,
          name: 'Organization One',
          logo: org1,
        },
        {
          id: 2,
          name: 'Organization Two',
          logo: org2,
        },
        {
          id: 3,
          name: 'Organization Three',
          logo: org3,
        },
        {
          id: 4,
          name: 'Organization Four',
          logo: org4,
        },
        {
          id: 5,
          name: 'Organization Five',
          logo: org5,
        },
      ],
    };
  },
};
</script>

<style>
/* Add custom styles if needed */
</style>
