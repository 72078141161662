<template>
    <!-- Navbar start -->
    <nav class="w-screen fixed bg-opacity-90 backdrop-blur-lg lg:px-20 flex justify-between z-50">
		<!-- Logo start -->
		<div class="flex p-4 justify-center text-white">
			<p class="tracking-widest font-semibold">CHERE</p>
			<p class="tracking-widest font-light">TECH</p>
		</div>
		<!-- Logo end -->

		<!-- Toggler start -->
		<button role="menubar" class="md:hidden pr-12" @click="navToggle()">
			<img src="@/assets/menubutton.webp" class="w-10 h-10" />
		</button>
		<!-- Toggler end -->

		<!-- Menu content start -->
		<div class="absolute left-0 text-white bg-sky-950 backdrop-blur-lg right-0 translate-y-16 bg-opacity-90 md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-6 md:flex-row md:static md:shadow-none md:translate-y-0" role="menu" aria-expanded="false">
			<!-- Links start -->
			<a href="javascript:void(0)" @click="scrollToSection('home-section')" role="menuitem">
				<p class="mx-4">Home</p>
			</a>

			<a href="javascript:void(0)" @click="scrollToSection('about-section')" role="menuitem">
				<p class="mx-4">About</p>
			</a>

			<a href="javascript:void(0)" @click="scrollToSection('services-section')" role="menuitem">
				<p class="mx-4">Explore</p>
			</a>

			<button href="javascript:void(0)" @click="scrollToSection('contact-section')" class="bg-gradient-to-r from-teal-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 p-2 rounded-lg" role="menuitem">
				Contact us
			</button>
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
    <!-- Navbar end -->
</template>

<script>

export default {
    methods: {
		// Toogle navbar mode
        navToggle() {
			let menu = document.querySelector("[role='menu']");
			let isExpanded = menu.getAttribute('aria-expanded');
			menu.setAttribute('aria-expanded', !isExpanded);
			menu.classList.toggle('hidden');
			menu.classList.toggle('flex');
		},
		scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>

<style>

</style>
